/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form, FormGroup, Button, Container, Row, Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import StructuralResponsesTable from './Table/index.js';
import RecordToRecordSelectMenu from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/support/RecordToRecordSelectMenu/index.jsx';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
|};

const StructuralResponseTableView = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    onBack,
    onNext,
  } = props;
  return (
    <Container>
      <Row>
        <Col>
          <h2>{heading}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="float-right">
            <Button id="addResponse" className="pr-0" to="add" color="link" tag={Link}>+ Add Response</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <StructuralResponsesTable form={form} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <Form novalidate>
            <FormGroup tag="fieldset">
              <legend>Structural Response Dispersion Modeling</legend>
              <GridField
                name={FORM_INPUT_NAMES.SR_RECORD_TO_RECORD_DISPERSION_MODEL}
                form={form}
                options={{
                  columnSize: 3,
                  labelText: 'Record-to-record Dispersion Model',
                  ComponentType: RecordToRecordSelectMenu,
                  includeToolTip: true,
                }}
              />
              <GridField
                name={FORM_INPUT_NAMES.SR_MODELING_DISPERSION}
                form={form}
                options={{
                  columnSize: 3,
                  labelText: 'Modeling Dispersion',
                  ComponentType: 'text',
                  includeToolTip: true,
                }}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pt-3">
            <BackButton onClick={onBack} />
            <NextButton onClick={onNext} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StructuralResponseTableView;
