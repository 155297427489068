/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Card, CardBody, Row, Col,
} from 'reactstrap';
import { FieldArray } from 'redux-form';
import { COMPONENT_ID, NAME, UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { COMPONENTS, MODELS } from '#constants/pathStrings.js';
import renderPerformanceGroups from './renderPerformanceGroups/index.js';

const makeHandleClick = (url) => () => {
  window.open(
    url,
    '_blank',
    'width=900,height=900'
  );
  return false;
};
type Props = {|
  fields: {
    get: Function,
    map: Function,
  },
  form: string,
  currentComponent: {},
|}

const renderEditComponent = ({
  fields,
  form,
  currentComponent,
}: Props): Element<'div'> | null => {
  if (!currentComponent) {
    return null;
  }
  const currentComponentId = currentComponent[COMPONENT_ID];
  const currentComponentName = currentComponent[NAME];
  const currentComponentUuid = currentComponent[UUID];
  const performanceGroups = fields.map((field, index) => {
    const { componentUuid } = fields.get(index);
    if (componentUuid === currentComponentUuid) {
      return (
        <FieldArray
          key={componentUuid}
          form={form}
          componentUuid={currentComponentUuid}
          name={`${field}.performanceGroups`}
          component={renderPerformanceGroups}
        />
      );
    }
    return null;
  });
  const handleClickSummary = makeHandleClick(`/${MODELS}/${COMPONENTS}/${currentComponentUuid}/`);
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <span className="float-right">
                <Button id="topCloseButton" tag={Link} color="link" to="..">Close</Button>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 id="componentIdHeading">
                {currentComponentId}
                <Button
                  color="link"
                  onClick={handleClickSummary}
                >
                  View definition
                </Button>
              </h1>
              <p>
                {currentComponentName}
              </p>

            </Col>
          </Row>
          <Row>
            <Col>
              <h2 id="component-population">Component Population</h2>
            </Col>
          </Row>
          {performanceGroups}
        </CardBody>
      </Card>
      <Row className="pt-3">
        <Col>
          <Button id="bottomCloseButton" tag={Link} to="..">Back to Components Table</Button>
        </Col>
      </Row>
    </div>
  );
};
export default renderEditComponent;
