// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import {
  RESIDUAL_DRIFT_DISTRIBUTION_MODEL_VALUES,
} from '@hbrisk/sp3-risk-model-support/models/app/attributes/values/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';

const options = [
  { text: 'Lognormal', value: RESIDUAL_DRIFT_DISTRIBUTION_MODEL_VALUES.LOG_NORM },
  { text: 'Weibull', value: RESIDUAL_DRIFT_DISTRIBUTION_MODEL_VALUES.WEIBULL },
];

type Props = {|
  form: string,
  name: string,
|};

const ResidualDriftDistributionModelSelectMenu = (props: Props): Element<typeof Field> => {
  const { form, name } = props;
  return (
    <Field
      form={form}
      id={name}
      name={name}
      type="select"
      component={RenderInput}
      inputClassName={classNames('custom-select', 'residual-drift-model-select')}
    >
      <option />
      {
        options.map((o) => (<option key={o.value} value={o.value}>{o.text}</option>))
      }
    </Field>
  );
};

export default ResidualDriftDistributionModelSelectMenu;
