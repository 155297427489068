// @flow

import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import residualDriftModels, { CURRENT, LEGACY } from '@hbrisk/sp3-risk-model-support/edps/ridr/models.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';

type Props = {|
  form: string,
  name: string,
|};

const getOptionsForStatus = (status) => residualDriftModels.reduce((acc, model) => (
  model.status === status
    ? [
      ...acc,
      { text: model.displayText, value: model.id },
    ]
    : acc
), []);

const groups = [
  {
    label: 'Current',
    options: getOptionsForStatus(CURRENT),
  },
  {
    label: 'Legacy',
    options: getOptionsForStatus(LEGACY),
  },
];

const ResidualDriftModelSelectMenu = (props: Props): Element<typeof Field> => {
  const { form, name } = props;
  return (
    <Field
      form={form}
      id={name}
      name={name}
      type="select"
      component={RenderInput}
      inputClassName={classNames('custom-select', 'residual-drift-model-select')}
    >
      <option />
      {
        groups.map((group) => (
          <optgroup key={group.label} label={group.label}>
            {
              group.options.map((o) => (
                <option key={o.value} value={o.value}>{o.text}</option>
              ))
            }
          </optgroup>
        ))
      }
    </Field>
  );
};

export default ResidualDriftModelSelectMenu;
