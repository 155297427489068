/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { SR_RECORD_TO_RECORD_DISPERSION_MODEL_VALUES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/values/index.js';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const { FEMA_P58, SIMPLIFIED } = SR_RECORD_TO_RECORD_DISPERSION_MODEL_VALUES;

const values = [
  { id: FEMA_P58, name: 'FEMA P-58' },
  { id: SIMPLIFIED, name: 'Simplified' },
];

const items = values.map(({ id, name }) => ({
  id,
  name,
  value: id,
}));

const RecordToRecordSelectMenu = ({
  form,
  name,
}: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    includeEmptyOption={false}
  />
);

export default RecordToRecordSelectMenu;
