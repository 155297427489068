/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import {
  selectIsStructuralResponseMethodMatch,
  selectStructuralResponseIsSuccessByEdpKey,
  selectStructuralResponseIsSuccessById,
} from '#selectors/entities/models.js';
import isUploadMethod from '#support/models/structuralResponses/structuralResponseIsUploadMethod.js';
import groundMotionIsUploadMethod from '#support/models/groundMotion/groundMotionIsUploadMethod.js';
import ResponseDetail from './ResponseDetail.jsx';
import { modelTypesByForm } from '#constants/models/modelTypes/index.js';

const mapStateToProps = (state, ownProps) => {
  const {
    modelId, edpKey, method, responseId, form,
  } = ownProps;
  const {
    selectFormFieldVisibility,
  } = modelTypesByForm[form];

  const values = getFormValues(form)(state);
  const { includeDesignIntensities, groundMotionMethod } = values;

  const isStructuralResponseUpload = isUploadMethod(method);
  const shouldShowUploader = isStructuralResponseUpload
    && (!includeDesignIntensities || groundMotionIsUploadMethod(groundMotionMethod));
  const isMatch = selectIsStructuralResponseMethodMatch(state, { modelId, edpKey, method });
  const isSuccess = isStructuralResponseUpload
    ? selectStructuralResponseIsSuccessById(state, { modelId, responseId })
    : selectStructuralResponseIsSuccessByEdpKey(state, { modelId, edpKey });

  const shouldShowResponse = isMatch && isSuccess;
  return {
    shouldShowResponse,
    shouldShowUploader,
    showResidualDriftModelDir1:
      selectFormFieldVisibility(state)(FORM_INPUT_NAMES.RESIDUAL_DRIFT_MODEL_DIR_1),
    showResidualDriftModelDir2:
      selectFormFieldVisibility(state)(FORM_INPUT_NAMES.RESIDUAL_DRIFT_MODEL_DIR_2),
  };
};

export default connect(mapStateToProps)(ResponseDetail);
