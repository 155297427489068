/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import AnalysisOptionsSection from './AnalysisOptionsSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { selectFormFieldVisibility } = modelTypesByForm[form];

  const showNumRealizations = selectFormFieldVisibility(state)('numRealizations');
  const showSimulationOptions = showNumRealizations;
  const showIncludeTimeBasedAnalysis = selectFormFieldVisibility(state)('includeTimeBasedAnalysis');

  return {
    showSimulationOptions,
    showIncludePerRealizationOutput: selectFormFieldVisibility(state)('includePerRealizationOutput'),
    showIncludeRepairTime: selectFormFieldVisibility(state)('includeRepairTime'),
    showNumRealizations,
    showIncludeTimeBasedAnalysis,
    showIncludeUSRCAnalysis: selectFormFieldVisibility(state)('includeUSRC'),
    showTruncateCollapseDistribution:
      selectFormFieldVisibility(state)(FORM_INPUT_NAMES.TRUNCATE_COLLAPSE_DISTRIBUTION),
    showTruncateCollapseDistributionLevel:
      selectFormFieldVisibility(state)(FORM_INPUT_NAMES.TRUNCATE_COLLAPSE_DISTRIBUTION_LEVEL),
    showTruncateComponentDistribution:
      selectFormFieldVisibility(state)(FORM_INPUT_NAMES.TRUNCATE_COMPONENT_DISTRIBUTION),
    showTruncateComponentDistributionLevel:
      selectFormFieldVisibility(state)(FORM_INPUT_NAMES.TRUNCATE_COMPONENT_DISTRIBUTION_LEVEL),
  };
};

export default connect(mapStateToProps)(AnalysisOptionsSection);
